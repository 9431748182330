import Img from "gatsby-image"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { rhythm } from "../utils/typography"

import logo from "../../content/assets/covid-trace-logo-nv.svg"
import appleBadge from "../../content/assets/apple_app_store_badge.svg"
import googleBadge from "../../content/assets/google_play_badge.png"

const HomePage = ({ data, location }) => {
  const siteTitle = "COVID Trace"

  return (
    <Layout location={location}>
      <SEO
        title="COVID Trace - a scalable, exposure notification app that respects privacy"
        description="COVID Trace is a non-profit offering a COVID-19 exposure notification app for iOS and Android. People using COVID Trace can expect privacy and simplicity. With COVID Trace, health departments get an app and metrics that are an extension of their existing efforts."
        image={data.preview}
      />
      <article>
        <header>
          <h1 style={{ marginBottom: rhythm(1 / 4) }}>
            <img
              alt="COVID Trace Icon"
              className="logo"
              src={logo}
              width={50}
            />{" "}
            {siteTitle}
          </h1>
          <div>
            <a href="https://apps.apple.com/us/app/id1524365131">
              <img
                className="badge"
                src={appleBadge}
                alt="App Store Download"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=gov.nv.dhhs.en">
              <img
                className="badge"
                src={googleBadge}
                alt="Google Play Store Download"
              />
            </a>
          </div>
        </header>
        <h3>Stop Covid-19. Speed Up Nevada's Recovery.</h3>
        <p>
          COVID Trace is a free, easy-to-use mobile phone app that gives us the
          information we need to fight COVID-19, without compromising your
          privacy.
        </p>
      </article>
      <div>
        <a
          title="Learn more about COVID Trace"
          href="https://nvhealthresponse.nv.gov/covidtrace/"
        >
          <Img
            fluid={data.preview.childImageSharp.fluid}
            durationFadeIn={500}
            backgroundColor="#005a9c"
          />
        </a>
      </div>
      <div className="download">
        <p>
          Visit{" "}
          <a href="https://nvhealthresponse.nv.gov/covidtrace/">
            Nevada Health Response
          </a>{" "}
          for more information about COVID Trace.
        </p>
      </div>
      <div>
        <h3>In The News</h3>
        <a href="https://www.youtube.com/watch?v=Is1SdvwuBq0">
          Here's how coronavirus contact tracing apps work
        </a>
        <q>CNBC TV</q>
        <a href="https://www.geekwire.com/2020/nevada-dept-health-launches-covid-trace-app-developed-seattle-tech-veterans/">
          Nevada Dept. of Health launches COVID Trace app developed by Seattle
          tech veterans
        </a>
        <q>Geekwire</q>
        <a href="https://www.fox5vegas.com/news/nevada-launches-covid-19-contact-tracing-app/article_f11e1552-e435-11ea-9049-2b7a4fc66491.html">
          Nevada launches COVID-19 contact tracing app
        </a>
        <q>Fox5 Las Vegas</q>
        <a href="https://thenevadaindependent.com/article/nevada-launches-contact-tracing-app-to-anonymously-alert-visitors-residents-of-possible-covid-19-exposures">
          Nevada launches contact tracing app to anonymously alert visitors,
          residents of possible COVID-19 exposures
        </a>
        <q>The Nevada Independent</q>
        <a href="https://www.ktnv.com/news/coronavirus/covid-trace-nevada-smartphone-app-launches-today">
          COVID Trace Nevada smartphone app launches today
        </a>
        <q>KTNV 13</q>
        <a href="https://news3lv.com/news/local/nevada-launches-covid-trace-contact-tracing-app">
          Find out if you've been exposed to coronavirus with new 'COVID Trace'
          contact-tracing app
        </a>
        <q>News3 LV</q>
        <a href="https://www.8newsnow.com/news/local-news/new-statewide-app-traces-covid-19-contact/">
          New Nevada app traces COVID-19 contact
        </a>
        <q>KLAS - 8 News</q>
        <a href="https://kxnt.radio.com/articles/news/nevada-launching-contact-tracing-app-today">
          Nevada Launching Contact Tracing App Today
        </a>
        <q>Talk Radio 840 KNKT</q>
        <a href="https://www.rgj.com/story/news/2020/08/24/coronavirus-nevada-launches-free-contact-tracing-app-covid-trace/5623236002/">
          Nevada launches free contact tracing app “COVID Trace”
        </a>
        <q>Reno Gazette-Journal</q>
      </div>
      <div id="partner">
        <h3>Get In Touch</h3>
        <p>
          For press and media inquiries, feedback, or questions about COVID
          Trace <a href="mailto:hello@covidtrace.com">contact&nbsp;us</a>.
        </p>
      </div>
      <style jsx>{`
        :global(body) {
          background: #005a9c;
          color: #fff;
        }

        :global(a) {
          color: #ffcf53;
        }

        :global(footer p) {
          color: #fff;
        }

        h1 {
          white-space: nowrap;
        }

        :global(strong.privacy) {
          color: inherit;
          font-weight: bold;
        }

        header {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }

        header h1 {
          margin: 0;
          flex-grow: 1;
        }

        header > div {
          display: flex;
        }

        header img.badge {
          display: block;
          margin: 0;
          height: 50px;
        }

        header a {
          box-shadow: none;
          margin-left: 1em;
        }

        img.logo {
          vertical-align: text-bottom;
          margin: 0;
          border-radius: 7px;
        }

        .download {
          text-align: center;
          margin-top: 30px;
        }

        q {
          display: block;
          margin-bottom: 1em;
          font-size: smaller;
        }

        q:before,
        q:after {
          content: "";
        }

        .btn {
          background-color: #df4f4c;
          color: #fff;
          padding: 8px;
          box-shadow: none;
          border-radius: 5px;
        }
      `}</style>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    preview: file(relativePath: { eq: "announcing-covid-trace/preview.png" }) {
      childImageSharp {
        fixed(width: 900, quality: 100) {
          src
        }
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    timeline: file(
      relativePath: { eq: "announcing-covid-trace/covidtrace_timeline.png" }
    ) {
      childImageSharp {
        original {
          src
        }
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    dashboard: file(
      relativePath: { eq: "announcing-covid-trace/covidtrace_dashboard.png" }
    ) {
      childImageSharp {
        original {
          src
        }
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default HomePage
