import React from "react"
import Helmet from "react-helmet"
import HomePage from "./index"
import { graphql } from "gatsby"

import preview from "../../content/assets/covid-trace-nevada-app-screenshot.png"

const SharePage = ({ data, location }) => {
  return (
    <>
      <Helmet>
        <title>COVID Trace Nevada</title>
        <meta property="og:title" content="COVID Trace Nevada" />
        <meta property="og:description"
              content="The Nevada COVID Trace app helps reduce the spread of
                       coronavirus in Nevada helping everyone stay safe. Nevada
                       COVID Trace doesn’t require personal information helping
                       protect your privacy.

                       COVID Trace is a contact tracing mobile app developed by
                       the Nevada Department of Health and Human Services."
              />
        <meta property="og:image"
              content={preview}
              />
        <meta property="og:image:type" content="image/png"/>
        <meta property="og:image:width" content="1242"/>
        <meta property="og:image:height" content="2208"/>
        <meta name="twitter:title" content="COVID Trace Nevada"/>
        <meta property="twitter:description"
              content="The Nevada COVID Trace app helps reduce the spread of
                       coronavirus in Nevada helping everyone stay safe. Nevada
                       COVID Trace doesn’t require personal information helping
                       protect your privacy.

                       COVID Trace is a contact tracing mobile app developed by
                       the Nevada Department of Health and Human Services."
        />
        <meta name="twitter:card" content="app"/>
        <meta name="twitter:app:name:iphone" content="COVID Trace Nevada" />
        <meta name="twitter:app:id:iphone" content="id1524365131" />
        <meta name="twitter:app:name:googleplay" content="COVID Trace Nevada" />
        <meta name="twitter:app:id:googleplay" content="gov.nv.dhhs.en" />
        <meta property="fb:app_id" content="116556461780510"/>
        <meta name="apple-itunes-app" content="app-id=375380948, app-argument=https://apps.apple.com/us/app/covid-trace-nevada/id1524365131"/>
        <script>{`
          var appStoreURL = "https://apps.apple.com/us/app/id1524365131";
          var playStoreURL = "https://play.google.com/store/apps/details?id=gov.nv.dhhs.en";
          var websiteURL = "https://nvhealthresponse.nv.gov/covidtrace/";

          var iPhoneUserAgentRe = /Mozilla\\/5.0 \\(iPhone/;
          var androidUserAgentRe = /Mozilla\\/5.0 \\([^)]*Android/;

          if (iPhoneUserAgentRe.test(navigator.userAgent)) {
            location = appStoreURL;
          } else if (androidUserAgentRe.test(navigator.userAgent)) {
            location = playStoreURL;
          } else {
            location = websiteURL;
          }
        `}</script>
      </Helmet>
    </>
  )
}

export default SharePage
